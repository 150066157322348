







































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetEyeReport } from "@/request/research";
import Report from "../components//report.vue";
@Component({
  components: {
    Report,
  },
})
export default class Name extends Vue {
  private id: any = "";
  private type: any = "标注报告";
  private data: any = {};
  private data1: any = {
    认证号: "1234567890",
    来源: "民族医特色诊疗数据库-傣医班",
    标注完成时间: "2024-01-12 12:21:34",
    审核完成时间: "2024-01-12 12:21:34",
    创建人: "张三",
    标注人: "李科",
    审核人: "刘武",
    得分: 80,
    student: {
      age: 0,
      eye_id: "eye665536559e752a001453617b",
      l_eye_image: [
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/1f7fb4ef1ce24c7d94fcc6cbc85678e7_1.png",
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/df16b17a1744472084b6bc16f5a77d4c_2.png",
      ],
      labels: [],
      left_eye: {
        白睛脉络: {
          上: {
            color: ["深红"],
            形状: ["曲张", "怒张"],
            诊断评分: {
              total_score: "18",
              脉络大小: "根部粗大或较粗大",
              脉络弯曲度: "呈螺旋状或曲度大",
              脉络末端斑点: "斑块或大斑点",
              脉络走向: "伸向瞳孔或离断",
              脉络颜色: "深红、绛红色",
              黑睛的改变: "凹陷穹窿代谢环变化瞳孔异常",
            },
            走行: ["走向瞳孔"],
          },
          下: {
            color: ["鲜红"],
            形状: ["根部粗大", "曲张"],
            诊断评分: {
              total_score: "14",
              脉络大小: "一般大",
              脉络弯曲度: "蛇行状",
              脉络末端斑点: "斑块或大斑点",
              脉络走向: "伸向其他",
              脉络颜色: "深红、绛红色",
              黑睛的改变: "代谢环变化黑线或黑点",
            },
            走行: ["走向瞳孔"],
          },
          右: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          右上: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          右下: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          左: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          左上: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "16",
              脉络大小: "根部粗大或较粗大",
              脉络弯曲度: "呈螺旋状或曲度大",
              脉络末端斑点: "小斑点",
              脉络走向: "伸向瞳孔或离断",
              脉络颜色: "深红、绛红色",
              黑睛的改变: "代谢环变化黑线或黑点",
            },
            走行: [],
          },
          左下: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          特殊眼征: {
            特殊眼征: [],
          },
        },
        黑睛: {
          color: {
            color: ["变浅"],
          },
          同心圆状白线: {
            全部: ["2圈"],
            同心圆状白线: "无",
            局部: ["2点"],
          },
          坑洞: {
            位置: ["2点", "12点"],
            坑洞: "无",
          },
          质地: {
            质地: ["2度"],
          },
          辐射状黑线: {
            位置: ["2点", "7点"],
            辐射状黑线: "无",
          },
          黑斑黑点: {
            位置: ["7点", "8点"],
            黑斑黑点: "有",
          },
          黑睛七环: {
            代谢环: ["正常"],
            环七: ["皮垢环"],
            肠道环: ["扩大"],
          },
        },
        专家建议: {
          专家建议:
            "虹膜的密度高，具有较好的厚度，提示人抗病能力强，精力体力充足，患病后康复快，容易病愈。",
          按诊断分: {
            中医疾病: [
              {
                id: "7．9",
                名称: "胃痞",
              },
            ],
            中医证型: [
              {
                id: "ABRD20",
                名称: "热毒壅盛证",
              },
              {
                id: "ABRD60",
                名称: "热毒凝结证",
              },
            ],
            疾病分型: "Ⅱ型",
            病程: "第Ⅱ阶段",
            西医疾病: [
              {
                id: "R10.102",
                名称: "胃痛",
              },
              {
                id: "K29.701",
                名称: "残胃炎",
              },
              {
                id: "K92.201",
                名称: "胃出血",
              },
              {
                id: "K31.700",
                名称: "胃息肉",
              },
              {
                id: "K31.400",
                名称: "胃憩室",
              },
              {
                id: "K31.811",
                名称: "胃下垂",
              },
              {
                id: "K29.700",
                名称: "胃炎",
              },
            ],
          },
          诊断评分: {
            total_score: "",
            脉络大小: "",
            脉络弯曲度: "",
            脉络末端斑点: "",
            脉络走向: "",
            脉络颜色: "",
            黑睛的改变: "",
          },
        },
      },
      name: "张三",
      patient_id: "5cb106840dbb",
      project_id: "pro622ededf9e752a037f8daa62",
      r_eye_image: [
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/bfc034513d48463cbc8f070e73eb956d_左1.png",
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/7d7050c7dd4d42a3a273e73c3cc9b9a8_左3.png",
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/9d19bb608d7b4c6f9f4bbbcd88dba446_左4.png",
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/5ceee9c26ee74491937fc33df39160e6_左2.png",
      ],
      right_eye: {
        白睛脉络: {
          上: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          下: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          右: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          右上: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          右下: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          左: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          左上: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          左下: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          特殊眼征: {
            特殊眼征: [],
          },
        },
        黑睛: {
          color: {
            color: ["正常"],
          },
          同心圆状白线: {
            全部: ["3圈"],
            同心圆状白线: "无",
            局部: ["2点", "8点"],
          },
          坑洞: {
            位置: ["3点"],
            坑洞: "无",
          },
          质地: {
            质地: ["2度"],
          },
          辐射状黑线: {
            位置: ["7点", "9点"],
            辐射状黑线: "无",
          },
          黑斑黑点: {
            位置: ["7点"],
            黑斑黑点: "无",
          },
          黑睛七环: {
            代谢环: ["偏斜"],
            环七: [],
            肠道环: ["黑斑"],
          },
        },
        专家建议: {
          专家建议:
            "虹膜的密度高，具有较好的厚度，提示人抗病能力强，精力体力充足，患病后康复快，容易病愈。",
          按诊断分: {
            中医疾病: [
              {
                id: "7．9",
                名称: "胃痞",
              },
            ],
            中医证型: [
              {
                id: "ABRD20",
                名称: "热毒壅盛证",
              },
              {
                id: "ABRD60",
                名称: "热毒凝结证",
              },
            ],
            疾病分型: "Ⅱ型",
            病程: "第Ⅱ阶段",
            西医疾病: [
              {
                id: "R10.102",
                名称: "胃痛",
              },
              {
                id: "K29.701",
                名称: "残胃炎",
              },
              {
                id: "K92.201",
                名称: "胃出血",
              },
              {
                id: "K31.700",
                名称: "胃息肉",
              },
              {
                id: "K31.400",
                名称: "胃憩室",
              },
              {
                id: "K31.811",
                名称: "胃下垂",
              },
              {
                id: "K29.700",
                名称: "胃炎",
              },
            ],
          },
          诊断评分: {
            total_score: "",
            脉络大小: "",
            脉络弯曲度: "",
            脉络末端斑点: "",
            脉络走向: "",
            脉络颜色: "",
            黑睛的改变: "",
          },
        },
      },
      sex: "",
      source: "测试库",
      zhenci: 1,
      zhenci_id: "665536559e752a0014536178",
      专家建议: {
        专家建议: "",
        按诊断分: {
          中医疾病: [],
          中医证型: [],
          疾病分型: "",
          病程: "",
          西医疾病: [],
        },
        诊断评分: {
          total_score: "",
          脉络大小: "",
          脉络弯曲度: "",
          脉络末端斑点: "",
          脉络走向: "",
          脉络颜色: "",
          黑睛的改变: "",
        },
      },
      检测时间: "2024-05-28 09:41:41",
      轨迹: {
        上: [
          {
            data: {
              area: 3257.9011,
              height: 789,
              path: [
                {
                  offsetX: 666,
                  offsetY: 444,
                },
                {
                  offsetX: 665,
                  offsetY: 446,
                },
                {
                  offsetX: 661,
                  offsetY: 449,
                },
                {
                  offsetX: 656,
                  offsetY: 455,
                },
                {
                  offsetX: 650,
                  offsetY: 461,
                },
                {
                  offsetX: 644,
                  offsetY: 468,
                },
                {
                  offsetX: 638,
                  offsetY: 476,
                },
                {
                  offsetX: 627,
                  offsetY: 489,
                },
                {
                  offsetX: 621,
                  offsetY: 499,
                },
                {
                  offsetX: 615,
                  offsetY: 508,
                },
                {
                  offsetX: 612,
                  offsetY: 513,
                },
                {
                  offsetX: 606,
                  offsetY: 525,
                },
                {
                  offsetX: 603,
                  offsetY: 531,
                },
                {
                  offsetX: 601,
                  offsetY: 538,
                },
                {
                  offsetX: 599,
                  offsetY: 546,
                },
                {
                  offsetX: 597,
                  offsetY: 554,
                },
                {
                  offsetX: 596,
                  offsetY: 560,
                },
                {
                  offsetX: 595,
                  offsetY: 566,
                },
                {
                  offsetX: 594,
                  offsetY: 572,
                },
                {
                  offsetX: 593,
                  offsetY: 587,
                },
                {
                  offsetX: 592,
                  offsetY: 593,
                },
                {
                  offsetX: 592,
                  offsetY: 599,
                },
                {
                  offsetX: 590,
                  offsetY: 611,
                },
                {
                  offsetX: 588,
                  offsetY: 619,
                },
                {
                  offsetX: 587,
                  offsetY: 627,
                },
                {
                  offsetX: 586,
                  offsetY: 634,
                },
                {
                  offsetX: 586,
                  offsetY: 641,
                },
                {
                  offsetX: 585,
                  offsetY: 648,
                },
                {
                  offsetX: 584,
                  offsetY: 653,
                },
                {
                  offsetX: 583,
                  offsetY: 656,
                },
                {
                  offsetX: 583,
                  offsetY: 660,
                },
                {
                  offsetX: 582,
                  offsetY: 668,
                },
                {
                  offsetX: 581,
                  offsetY: 673,
                },
                {
                  offsetX: 581,
                  offsetY: 676,
                },
                {
                  offsetX: 581,
                  offsetY: 677,
                },
                {
                  offsetX: 581,
                  offsetY: 678,
                },
                {
                  offsetX: 581,
                  offsetY: 676,
                },
                {
                  offsetX: 586,
                  offsetY: 673,
                },
                {
                  offsetX: 591,
                  offsetY: 668,
                },
                {
                  offsetX: 603,
                  offsetY: 658,
                },
                {
                  offsetX: 607,
                  offsetY: 653,
                },
                {
                  offsetX: 611,
                  offsetY: 649,
                },
                {
                  offsetX: 613,
                  offsetY: 646,
                },
                {
                  offsetX: 617,
                  offsetY: 640,
                },
                {
                  offsetX: 619,
                  offsetY: 636,
                },
                {
                  offsetX: 622,
                  offsetY: 631,
                },
                {
                  offsetX: 624,
                  offsetY: 628,
                },
                {
                  offsetX: 626,
                  offsetY: 625,
                },
                {
                  offsetX: 627,
                  offsetY: 621,
                },
                {
                  offsetX: 631,
                  offsetY: 615,
                },
                {
                  offsetX: 634,
                  offsetY: 609,
                },
                {
                  offsetX: 637,
                  offsetY: 599,
                },
                {
                  offsetX: 640,
                  offsetY: 591,
                },
                {
                  offsetX: 644,
                  offsetY: 582,
                },
                {
                  offsetX: 646,
                  offsetY: 577,
                },
                {
                  offsetX: 651,
                  offsetY: 568,
                },
                {
                  offsetX: 657,
                  offsetY: 558,
                },
                {
                  offsetX: 661,
                  offsetY: 552,
                },
                {
                  offsetX: 664,
                  offsetY: 546,
                },
                {
                  offsetX: 667,
                  offsetY: 540,
                },
                {
                  offsetX: 670,
                  offsetY: 534,
                },
                {
                  offsetX: 672,
                  offsetY: 529,
                },
                {
                  offsetX: 675,
                  offsetY: 523,
                },
                {
                  offsetX: 676,
                  offsetY: 520,
                },
                {
                  offsetX: 677,
                  offsetY: 518,
                },
                {
                  offsetX: 677,
                  offsetY: 517,
                },
                {
                  offsetX: 678,
                  offsetY: 516,
                },
                {
                  offsetX: 678,
                  offsetY: 515,
                },
                {
                  offsetX: 681,
                  offsetY: 510,
                },
                {
                  offsetX: 681,
                  offsetY: 507,
                },
                {
                  offsetX: 682,
                  offsetY: 505,
                },
                {
                  offsetX: 683,
                  offsetY: 501,
                },
                {
                  offsetX: 684,
                  offsetY: 498,
                },
                {
                  offsetX: 685,
                  offsetY: 494,
                },
                {
                  offsetX: 685,
                  offsetY: 491,
                },
                {
                  offsetX: 686,
                  offsetY: 486,
                },
                {
                  offsetX: 687,
                  offsetY: 483,
                },
                {
                  offsetX: 687,
                  offsetY: 478,
                },
                {
                  offsetX: 687,
                  offsetY: 472,
                },
                {
                  offsetX: 687,
                  offsetY: 469,
                },
                {
                  offsetX: 687,
                  offsetY: 468,
                },
                {
                  offsetX: 687,
                  offsetY: 467,
                },
                {
                  offsetX: 687,
                  offsetY: 465,
                },
                {
                  offsetX: 687,
                  offsetY: 464,
                },
                {
                  offsetX: 687,
                  offsetY: 463,
                },
                {
                  offsetX: 687,
                  offsetY: 461,
                },
                {
                  offsetX: 686,
                  offsetY: 460,
                },
                {
                  offsetX: 686,
                  offsetY: 458,
                },
                {
                  offsetX: 685,
                  offsetY: 454,
                },
                {
                  offsetX: 684,
                  offsetY: 451,
                },
                {
                  offsetX: 683,
                  offsetY: 448,
                },
                {
                  offsetX: 683,
                  offsetY: 446,
                },
                {
                  offsetX: 682,
                  offsetY: 446,
                },
                {
                  offsetX: 682,
                  offsetY: 445,
                },
                {
                  offsetX: 682,
                  offsetY: 444,
                },
                {
                  offsetX: 681,
                  offsetY: 444,
                },
                {
                  offsetX: 680,
                  offsetY: 443,
                },
                {
                  offsetX: 679,
                  offsetY: 442,
                },
                {
                  offsetX: 678,
                  offsetY: 442,
                },
                {
                  offsetX: 677,
                  offsetY: 442,
                },
                {
                  offsetX: 677,
                  offsetY: 441,
                },
                {
                  offsetX: 676,
                  offsetY: 441,
                },
                {
                  offsetX: 675,
                  offsetY: 441,
                },
                {
                  offsetX: 674,
                  offsetY: 441,
                },
                {
                  offsetX: 673,
                  offsetY: 441,
                },
                {
                  offsetX: 672,
                  offsetY: 441,
                },
                {
                  offsetX: 671,
                  offsetY: 441,
                },
                {
                  offsetX: 670,
                  offsetY: 441,
                },
                {
                  offsetX: 669,
                  offsetY: 441,
                },
                {
                  offsetX: 668,
                  offsetY: 441,
                },
                {
                  offsetX: 667,
                  offsetY: 441,
                },
              ],
              startPoint: {
                offsetX: 667,
                offsetY: 443,
              },
              width: 1018,
            },
            id: "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/df16b17a1744472084b6bc16f5a77d4c_2.png",
          },
        ],
        下: [
          {
            data: {
              area: 3543.2647,
              height: 789,
              path: [
                {
                  offsetX: 406,
                  offsetY: 471,
                },
                {
                  offsetX: 408,
                  offsetY: 473,
                },
                {
                  offsetX: 409,
                  offsetY: 478,
                },
                {
                  offsetX: 411,
                  offsetY: 484,
                },
                {
                  offsetX: 413,
                  offsetY: 496,
                },
                {
                  offsetX: 415,
                  offsetY: 504,
                },
                {
                  offsetX: 418,
                  offsetY: 517,
                },
                {
                  offsetX: 424,
                  offsetY: 535,
                },
                {
                  offsetX: 428,
                  offsetY: 553,
                },
                {
                  offsetX: 432,
                  offsetY: 568,
                },
                {
                  offsetX: 433,
                  offsetY: 578,
                },
                {
                  offsetX: 435,
                  offsetY: 582,
                },
                {
                  offsetX: 436,
                  offsetY: 587,
                },
                {
                  offsetX: 439,
                  offsetY: 597,
                },
                {
                  offsetX: 443,
                  offsetY: 606,
                },
                {
                  offsetX: 445,
                  offsetY: 613,
                },
                {
                  offsetX: 448,
                  offsetY: 619,
                },
                {
                  offsetX: 449,
                  offsetY: 623,
                },
                {
                  offsetX: 452,
                  offsetY: 628,
                },
                {
                  offsetX: 454,
                  offsetY: 631,
                },
                {
                  offsetX: 457,
                  offsetY: 636,
                },
                {
                  offsetX: 462,
                  offsetY: 640,
                },
                {
                  offsetX: 469,
                  offsetY: 647,
                },
                {
                  offsetX: 475,
                  offsetY: 652,
                },
                {
                  offsetX: 482,
                  offsetY: 657,
                },
                {
                  offsetX: 487,
                  offsetY: 660,
                },
                {
                  offsetX: 491,
                  offsetY: 662,
                },
                {
                  offsetX: 494,
                  offsetY: 664,
                },
                {
                  offsetX: 497,
                  offsetY: 664,
                },
                {
                  offsetX: 499,
                  offsetY: 664,
                },
                {
                  offsetX: 502,
                  offsetY: 664,
                },
                {
                  offsetX: 503,
                  offsetY: 664,
                },
                {
                  offsetX: 504,
                  offsetY: 661,
                },
                {
                  offsetX: 505,
                  offsetY: 660,
                },
                {
                  offsetX: 508,
                  offsetY: 650,
                },
                {
                  offsetX: 510,
                  offsetY: 646,
                },
                {
                  offsetX: 512,
                  offsetY: 639,
                },
                {
                  offsetX: 514,
                  offsetY: 630,
                },
                {
                  offsetX: 514,
                  offsetY: 626,
                },
                {
                  offsetX: 514,
                  offsetY: 619,
                },
                {
                  offsetX: 514,
                  offsetY: 614,
                },
                {
                  offsetX: 514,
                  offsetY: 608,
                },
                {
                  offsetX: 513,
                  offsetY: 601,
                },
                {
                  offsetX: 513,
                  offsetY: 595,
                },
                {
                  offsetX: 511,
                  offsetY: 586,
                },
                {
                  offsetX: 508,
                  offsetY: 578,
                },
                {
                  offsetX: 507,
                  offsetY: 573,
                },
                {
                  offsetX: 503,
                  offsetY: 565,
                },
                {
                  offsetX: 497,
                  offsetY: 553,
                },
                {
                  offsetX: 492,
                  offsetY: 543,
                },
                {
                  offsetX: 488,
                  offsetY: 535,
                },
                {
                  offsetX: 484,
                  offsetY: 527,
                },
                {
                  offsetX: 476,
                  offsetY: 515,
                },
                {
                  offsetX: 474,
                  offsetY: 512,
                },
                {
                  offsetX: 470,
                  offsetY: 507,
                },
                {
                  offsetX: 465,
                  offsetY: 500,
                },
                {
                  offsetX: 462,
                  offsetY: 495,
                },
                {
                  offsetX: 457,
                  offsetY: 489,
                },
                {
                  offsetX: 454,
                  offsetY: 485,
                },
                {
                  offsetX: 451,
                  offsetY: 480,
                },
                {
                  offsetX: 450,
                  offsetY: 478,
                },
                {
                  offsetX: 447,
                  offsetY: 475,
                },
                {
                  offsetX: 445,
                  offsetY: 472,
                },
                {
                  offsetX: 444,
                  offsetY: 469,
                },
                {
                  offsetX: 441,
                  offsetY: 466,
                },
                {
                  offsetX: 439,
                  offsetY: 464,
                },
                {
                  offsetX: 436,
                  offsetY: 460,
                },
                {
                  offsetX: 433,
                  offsetY: 457,
                },
                {
                  offsetX: 431,
                  offsetY: 456,
                },
                {
                  offsetX: 430,
                  offsetY: 455,
                },
                {
                  offsetX: 426,
                  offsetY: 453,
                },
                {
                  offsetX: 424,
                  offsetY: 453,
                },
                {
                  offsetX: 422,
                  offsetY: 452,
                },
                {
                  offsetX: 421,
                  offsetY: 452,
                },
                {
                  offsetX: 420,
                  offsetY: 451,
                },
                {
                  offsetX: 417,
                  offsetY: 451,
                },
                {
                  offsetX: 415,
                  offsetY: 451,
                },
                {
                  offsetX: 414,
                  offsetY: 451,
                },
                {
                  offsetX: 413,
                  offsetY: 451,
                },
                {
                  offsetX: 412,
                  offsetY: 452,
                },
                {
                  offsetX: 412,
                  offsetY: 453,
                },
                {
                  offsetX: 412,
                  offsetY: 454,
                },
                {
                  offsetX: 412,
                  offsetY: 456,
                },
                {
                  offsetX: 412,
                  offsetY: 459,
                },
                {
                  offsetX: 412,
                  offsetY: 460,
                },
                {
                  offsetX: 412,
                  offsetY: 461,
                },
                {
                  offsetX: 412,
                  offsetY: 462,
                },
                {
                  offsetX: 411,
                  offsetY: 463,
                },
                {
                  offsetX: 411,
                  offsetY: 466,
                },
                {
                  offsetX: 411,
                  offsetY: 468,
                },
                {
                  offsetX: 410,
                  offsetY: 471,
                },
                {
                  offsetX: 410,
                  offsetY: 474,
                },
                {
                  offsetX: 410,
                  offsetY: 475,
                },
                {
                  offsetX: 410,
                  offsetY: 478,
                },
                {
                  offsetX: 410,
                  offsetY: 479,
                },
                {
                  offsetX: 410,
                  offsetY: 481,
                },
                {
                  offsetX: 410,
                  offsetY: 482,
                },
                {
                  offsetX: 410,
                  offsetY: 483,
                },
                {
                  offsetX: 411,
                  offsetY: 483,
                },
                {
                  offsetX: 411,
                  offsetY: 484,
                },
              ],
              startPoint: {
                offsetX: 404,
                offsetY: 469,
              },
              width: 1018,
            },
            id: "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/df16b17a1744472084b6bc16f5a77d4c_2.png",
          },
        ],
        左上: [
          {
            data: {
              area: 16619.38905,
              height: 465,
              path: [
                {
                  offsetX: 153,
                  offsetY: 312,
                },
                {
                  offsetX: 154,
                  offsetY: 310,
                },
                {
                  offsetX: 156,
                  offsetY: 310,
                },
                {
                  offsetX: 160,
                  offsetY: 307,
                },
                {
                  offsetX: 163,
                  offsetY: 305,
                },
                {
                  offsetX: 167,
                  offsetY: 304,
                },
                {
                  offsetX: 173,
                  offsetY: 301,
                },
                {
                  offsetX: 180,
                  offsetY: 298,
                },
                {
                  offsetX: 188,
                  offsetY: 295,
                },
                {
                  offsetX: 196,
                  offsetY: 293,
                },
                {
                  offsetX: 207,
                  offsetY: 289,
                },
                {
                  offsetX: 216,
                  offsetY: 287,
                },
                {
                  offsetX: 230,
                  offsetY: 285,
                },
                {
                  offsetX: 243,
                  offsetY: 284,
                },
                {
                  offsetX: 255,
                  offsetY: 284,
                },
                {
                  offsetX: 267,
                  offsetY: 284,
                },
                {
                  offsetX: 278,
                  offsetY: 284,
                },
                {
                  offsetX: 286,
                  offsetY: 284,
                },
                {
                  offsetX: 294,
                  offsetY: 284,
                },
                {
                  offsetX: 298,
                  offsetY: 287,
                },
                {
                  offsetX: 302,
                  offsetY: 288,
                },
                {
                  offsetX: 304,
                  offsetY: 291,
                },
                {
                  offsetX: 305,
                  offsetY: 294,
                },
                {
                  offsetX: 306,
                  offsetY: 297,
                },
                {
                  offsetX: 306,
                  offsetY: 301,
                },
                {
                  offsetX: 306,
                  offsetY: 306,
                },
                {
                  offsetX: 306,
                  offsetY: 312,
                },
                {
                  offsetX: 306,
                  offsetY: 319,
                },
                {
                  offsetX: 305,
                  offsetY: 327,
                },
                {
                  offsetX: 303,
                  offsetY: 336,
                },
                {
                  offsetX: 299,
                  offsetY: 346,
                },
                {
                  offsetX: 296,
                  offsetY: 355,
                },
                {
                  offsetX: 292,
                  offsetY: 363,
                },
                {
                  offsetX: 287,
                  offsetY: 372,
                },
                {
                  offsetX: 282,
                  offsetY: 379,
                },
                {
                  offsetX: 277,
                  offsetY: 385,
                },
                {
                  offsetX: 271,
                  offsetY: 391,
                },
                {
                  offsetX: 265,
                  offsetY: 396,
                },
                {
                  offsetX: 257,
                  offsetY: 402,
                },
                {
                  offsetX: 249,
                  offsetY: 406,
                },
                {
                  offsetX: 240,
                  offsetY: 410,
                },
                {
                  offsetX: 232,
                  offsetY: 412,
                },
                {
                  offsetX: 221,
                  offsetY: 414,
                },
                {
                  offsetX: 210,
                  offsetY: 415,
                },
                {
                  offsetX: 198,
                  offsetY: 417,
                },
                {
                  offsetX: 186,
                  offsetY: 417,
                },
                {
                  offsetX: 175,
                  offsetY: 417,
                },
                {
                  offsetX: 163,
                  offsetY: 417,
                },
                {
                  offsetX: 153,
                  offsetY: 415,
                },
                {
                  offsetX: 145,
                  offsetY: 413,
                },
                {
                  offsetX: 139,
                  offsetY: 410,
                },
                {
                  offsetX: 134,
                  offsetY: 408,
                },
                {
                  offsetX: 132,
                  offsetY: 404,
                },
                {
                  offsetX: 131,
                  offsetY: 400,
                },
                {
                  offsetX: 130,
                  offsetY: 394,
                },
                {
                  offsetX: 130,
                  offsetY: 387,
                },
                {
                  offsetX: 132,
                  offsetY: 380,
                },
                {
                  offsetX: 135,
                  offsetY: 371,
                },
                {
                  offsetX: 142,
                  offsetY: 358,
                },
                {
                  offsetX: 149,
                  offsetY: 345,
                },
                {
                  offsetX: 158,
                  offsetY: 331,
                },
                {
                  offsetX: 168,
                  offsetY: 317,
                },
                {
                  offsetX: 180,
                  offsetY: 303,
                },
                {
                  offsetX: 192,
                  offsetY: 292,
                },
                {
                  offsetX: 207,
                  offsetY: 278,
                },
                {
                  offsetX: 226,
                  offsetY: 267,
                },
                {
                  offsetX: 242,
                  offsetY: 257,
                },
                {
                  offsetX: 263,
                  offsetY: 247,
                },
                {
                  offsetX: 283,
                  offsetY: 238,
                },
                {
                  offsetX: 300,
                  offsetY: 232,
                },
                {
                  offsetX: 314,
                  offsetY: 227,
                },
                {
                  offsetX: 326,
                  offsetY: 224,
                },
                {
                  offsetX: 333,
                  offsetY: 222,
                },
                {
                  offsetX: 339,
                  offsetY: 221,
                },
                {
                  offsetX: 341,
                  offsetY: 221,
                },
                {
                  offsetX: 342,
                  offsetY: 221,
                },
              ],
              startPoint: {
                offsetX: 152,
                offsetY: 312,
              },
              width: 600,
            },
            id: "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/1f7fb4ef1ce24c7d94fcc6cbc85678e7_1.png",
          },
        ],
        黑睛: [
          {
            data: {
              area: 11460.0079,
              height: 789,
              path: [
                {
                  offsetX: 599,
                  offsetY: 364,
                },
                {
                  offsetX: 587,
                  offsetY: 369,
                },
                {
                  offsetX: 582,
                  offsetY: 373,
                },
                {
                  offsetX: 579,
                  offsetY: 376,
                },
                {
                  offsetX: 576,
                  offsetY: 381,
                },
                {
                  offsetX: 573,
                  offsetY: 385,
                },
                {
                  offsetX: 569,
                  offsetY: 391,
                },
                {
                  offsetX: 565,
                  offsetY: 400,
                },
                {
                  offsetX: 562,
                  offsetY: 407,
                },
                {
                  offsetX: 561,
                  offsetY: 413,
                },
                {
                  offsetX: 559,
                  offsetY: 422,
                },
                {
                  offsetX: 559,
                  offsetY: 430,
                },
                {
                  offsetX: 559,
                  offsetY: 436,
                },
                {
                  offsetX: 559,
                  offsetY: 446,
                },
                {
                  offsetX: 560,
                  offsetY: 451,
                },
                {
                  offsetX: 561,
                  offsetY: 458,
                },
                {
                  offsetX: 564,
                  offsetY: 464,
                },
                {
                  offsetX: 570,
                  offsetY: 477,
                },
                {
                  offsetX: 578,
                  offsetY: 491,
                },
                {
                  offsetX: 589,
                  offsetY: 504,
                },
                {
                  offsetX: 610,
                  offsetY: 522,
                },
                {
                  offsetX: 628,
                  offsetY: 532,
                },
                {
                  offsetX: 644,
                  offsetY: 537,
                },
                {
                  offsetX: 657,
                  offsetY: 539,
                },
                {
                  offsetX: 676,
                  offsetY: 538,
                },
                {
                  offsetX: 698,
                  offsetY: 530,
                },
                {
                  offsetX: 717,
                  offsetY: 521,
                },
                {
                  offsetX: 737,
                  offsetY: 512,
                },
                {
                  offsetX: 758,
                  offsetY: 501,
                },
                {
                  offsetX: 772,
                  offsetY: 491,
                },
                {
                  offsetX: 783,
                  offsetY: 480,
                },
                {
                  offsetX: 793,
                  offsetY: 468,
                },
                {
                  offsetX: 798,
                  offsetY: 456,
                },
                {
                  offsetX: 803,
                  offsetY: 435,
                },
                {
                  offsetX: 805,
                  offsetY: 421,
                },
                {
                  offsetX: 807,
                  offsetY: 410,
                },
                {
                  offsetX: 808,
                  offsetY: 401,
                },
                {
                  offsetX: 808,
                  offsetY: 396,
                },
                {
                  offsetX: 808,
                  offsetY: 390,
                },
                {
                  offsetX: 808,
                  offsetY: 386,
                },
                {
                  offsetX: 808,
                  offsetY: 381,
                },
                {
                  offsetX: 805,
                  offsetY: 376,
                },
                {
                  offsetX: 803,
                  offsetY: 373,
                },
                {
                  offsetX: 800,
                  offsetY: 368,
                },
                {
                  offsetX: 794,
                  offsetY: 361,
                },
                {
                  offsetX: 791,
                  offsetY: 358,
                },
                {
                  offsetX: 786,
                  offsetY: 355,
                },
                {
                  offsetX: 779,
                  offsetY: 351,
                },
                {
                  offsetX: 774,
                  offsetY: 347,
                },
                {
                  offsetX: 770,
                  offsetY: 346,
                },
                {
                  offsetX: 765,
                  offsetY: 344,
                },
                {
                  offsetX: 760,
                  offsetY: 342,
                },
                {
                  offsetX: 756,
                  offsetY: 342,
                },
                {
                  offsetX: 751,
                  offsetY: 341,
                },
                {
                  offsetX: 747,
                  offsetY: 341,
                },
                {
                  offsetX: 743,
                  offsetY: 340,
                },
                {
                  offsetX: 737,
                  offsetY: 340,
                },
                {
                  offsetX: 733,
                  offsetY: 340,
                },
                {
                  offsetX: 728,
                  offsetY: 340,
                },
                {
                  offsetX: 722,
                  offsetY: 340,
                },
                {
                  offsetX: 718,
                  offsetY: 341,
                },
                {
                  offsetX: 714,
                  offsetY: 341,
                },
                {
                  offsetX: 709,
                  offsetY: 342,
                },
                {
                  offsetX: 704,
                  offsetY: 343,
                },
                {
                  offsetX: 700,
                  offsetY: 344,
                },
                {
                  offsetX: 694,
                  offsetY: 344,
                },
                {
                  offsetX: 688,
                  offsetY: 346,
                },
                {
                  offsetX: 682,
                  offsetY: 347,
                },
                {
                  offsetX: 675,
                  offsetY: 348,
                },
                {
                  offsetX: 672,
                  offsetY: 349,
                },
                {
                  offsetX: 669,
                  offsetY: 349,
                },
                {
                  offsetX: 666,
                  offsetY: 350,
                },
                {
                  offsetX: 664,
                  offsetY: 350,
                },
                {
                  offsetX: 660,
                  offsetY: 350,
                },
                {
                  offsetX: 658,
                  offsetY: 351,
                },
                {
                  offsetX: 655,
                  offsetY: 352,
                },
                {
                  offsetX: 653,
                  offsetY: 352,
                },
                {
                  offsetX: 652,
                  offsetY: 352,
                },
                {
                  offsetX: 651,
                  offsetY: 353,
                },
                {
                  offsetX: 649,
                  offsetY: 353,
                },
                {
                  offsetX: 647,
                  offsetY: 353,
                },
                {
                  offsetX: 645,
                  offsetY: 354,
                },
                {
                  offsetX: 642,
                  offsetY: 355,
                },
                {
                  offsetX: 640,
                  offsetY: 355,
                },
                {
                  offsetX: 637,
                  offsetY: 355,
                },
                {
                  offsetX: 635,
                  offsetY: 356,
                },
                {
                  offsetX: 632,
                  offsetY: 356,
                },
                {
                  offsetX: 628,
                  offsetY: 356,
                },
                {
                  offsetX: 625,
                  offsetY: 356,
                },
                {
                  offsetX: 622,
                  offsetY: 357,
                },
                {
                  offsetX: 620,
                  offsetY: 358,
                },
                {
                  offsetX: 618,
                  offsetY: 358,
                },
                {
                  offsetX: 614,
                  offsetY: 359,
                },
                {
                  offsetX: 612,
                  offsetY: 359,
                },
                {
                  offsetX: 611,
                  offsetY: 360,
                },
                {
                  offsetX: 610,
                  offsetY: 360,
                },
                {
                  offsetX: 609,
                  offsetY: 360,
                },
                {
                  offsetX: 608,
                  offsetY: 360,
                },
                {
                  offsetX: 607,
                  offsetY: 360,
                },
                {
                  offsetX: 607,
                  offsetY: 361,
                },
                {
                  offsetX: 606,
                  offsetY: 361,
                },
                {
                  offsetX: 605,
                  offsetY: 361,
                },
                {
                  offsetX: 604,
                  offsetY: 361,
                },
              ],
              startPoint: {
                offsetX: 604,
                offsetY: 363,
              },
              width: 1018,
            },
            id: "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/1f7fb4ef1ce24c7d94fcc6cbc85678e7_1.png",
          },
          {
            data: {
              area: 15742.558599999998,
              height: 789,
              path: [
                {
                  offsetX: 557,
                  offsetY: 278,
                },
                {
                  offsetX: 556,
                  offsetY: 278,
                },
                {
                  offsetX: 552,
                  offsetY: 278,
                },
                {
                  offsetX: 546,
                  offsetY: 278,
                },
                {
                  offsetX: 534,
                  offsetY: 280,
                },
                {
                  offsetX: 524,
                  offsetY: 283,
                },
                {
                  offsetX: 510,
                  offsetY: 289,
                },
                {
                  offsetX: 495,
                  offsetY: 299,
                },
                {
                  offsetX: 465,
                  offsetY: 324,
                },
                {
                  offsetX: 441,
                  offsetY: 347,
                },
                {
                  offsetX: 422,
                  offsetY: 371,
                },
                {
                  offsetX: 402,
                  offsetY: 399,
                },
                {
                  offsetX: 392,
                  offsetY: 418,
                },
                {
                  offsetX: 388,
                  offsetY: 431,
                },
                {
                  offsetX: 388,
                  offsetY: 440,
                },
                {
                  offsetX: 388,
                  offsetY: 444,
                },
                {
                  offsetX: 390,
                  offsetY: 449,
                },
                {
                  offsetX: 394,
                  offsetY: 458,
                },
                {
                  offsetX: 397,
                  offsetY: 465,
                },
                {
                  offsetX: 403,
                  offsetY: 477,
                },
                {
                  offsetX: 415,
                  offsetY: 491,
                },
                {
                  offsetX: 424,
                  offsetY: 500,
                },
                {
                  offsetX: 433,
                  offsetY: 506,
                },
                {
                  offsetX: 445,
                  offsetY: 512,
                },
                {
                  offsetX: 458,
                  offsetY: 516,
                },
                {
                  offsetX: 484,
                  offsetY: 518,
                },
                {
                  offsetX: 512,
                  offsetY: 520,
                },
                {
                  offsetX: 541,
                  offsetY: 520,
                },
                {
                  offsetX: 570,
                  offsetY: 520,
                },
                {
                  offsetX: 596,
                  offsetY: 518,
                },
                {
                  offsetX: 613,
                  offsetY: 514,
                },
                {
                  offsetX: 636,
                  offsetY: 504,
                },
                {
                  offsetX: 658,
                  offsetY: 482,
                },
                {
                  offsetX: 667,
                  offsetY: 467,
                },
                {
                  offsetX: 672,
                  offsetY: 454,
                },
                {
                  offsetX: 676,
                  offsetY: 439,
                },
                {
                  offsetX: 678,
                  offsetY: 426,
                },
                {
                  offsetX: 678,
                  offsetY: 413,
                },
                {
                  offsetX: 678,
                  offsetY: 397,
                },
                {
                  offsetX: 678,
                  offsetY: 383,
                },
                {
                  offsetX: 677,
                  offsetY: 373,
                },
                {
                  offsetX: 674,
                  offsetY: 367,
                },
                {
                  offsetX: 670,
                  offsetY: 360,
                },
                {
                  offsetX: 667,
                  offsetY: 357,
                },
                {
                  offsetX: 659,
                  offsetY: 348,
                },
                {
                  offsetX: 653,
                  offsetY: 344,
                },
                {
                  offsetX: 649,
                  offsetY: 340,
                },
                {
                  offsetX: 643,
                  offsetY: 334,
                },
                {
                  offsetX: 636,
                  offsetY: 329,
                },
                {
                  offsetX: 626,
                  offsetY: 322,
                },
                {
                  offsetX: 617,
                  offsetY: 315,
                },
                {
                  offsetX: 609,
                  offsetY: 310,
                },
                {
                  offsetX: 603,
                  offsetY: 306,
                },
                {
                  offsetX: 597,
                  offsetY: 302,
                },
                {
                  offsetX: 591,
                  offsetY: 299,
                },
                {
                  offsetX: 587,
                  offsetY: 296,
                },
                {
                  offsetX: 585,
                  offsetY: 295,
                },
                {
                  offsetX: 584,
                  offsetY: 295,
                },
                {
                  offsetX: 583,
                  offsetY: 294,
                },
                {
                  offsetX: 582,
                  offsetY: 293,
                },
                {
                  offsetX: 581,
                  offsetY: 293,
                },
                {
                  offsetX: 580,
                  offsetY: 293,
                },
                {
                  offsetX: 579,
                  offsetY: 293,
                },
                {
                  offsetX: 579,
                  offsetY: 292,
                },
                {
                  offsetX: 578,
                  offsetY: 292,
                },
                {
                  offsetX: 577,
                  offsetY: 292,
                },
                {
                  offsetX: 576,
                  offsetY: 291,
                },
                {
                  offsetX: 575,
                  offsetY: 291,
                },
                {
                  offsetX: 575,
                  offsetY: 290,
                },
                {
                  offsetX: 573,
                  offsetY: 290,
                },
                {
                  offsetX: 572,
                  offsetY: 289,
                },
                {
                  offsetX: 571,
                  offsetY: 288,
                },
                {
                  offsetX: 570,
                  offsetY: 288,
                },
                {
                  offsetX: 569,
                  offsetY: 287,
                },
                {
                  offsetX: 568,
                  offsetY: 287,
                },
                {
                  offsetX: 568,
                  offsetY: 286,
                },
                {
                  offsetX: 567,
                  offsetY: 285,
                },
                {
                  offsetX: 566,
                  offsetY: 285,
                },
                {
                  offsetX: 565,
                  offsetY: 285,
                },
                {
                  offsetX: 565,
                  offsetY: 284,
                },
                {
                  offsetX: 564,
                  offsetY: 284,
                },
                {
                  offsetX: 564,
                  offsetY: 283,
                },
                {
                  offsetX: 563,
                  offsetY: 283,
                },
                {
                  offsetX: 562,
                  offsetY: 283,
                },
                {
                  offsetX: 561,
                  offsetY: 283,
                },
                {
                  offsetX: 561,
                  offsetY: 282,
                },
                {
                  offsetX: 560,
                  offsetY: 281,
                },
                {
                  offsetX: 559,
                  offsetY: 281,
                },
                {
                  offsetX: 559,
                  offsetY: 280,
                },
                {
                  offsetX: 558,
                  offsetY: 280,
                },
                {
                  offsetX: 557,
                  offsetY: 280,
                },
                {
                  offsetX: 556,
                  offsetY: 280,
                },
                {
                  offsetX: 556,
                  offsetY: 279,
                },
              ],
              startPoint: {
                offsetX: 558,
                offsetY: 278,
              },
              width: 1018,
            },
            id: "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/7d7050c7dd4d42a3a273e73c3cc9b9a8_左3.png",
          },
        ],
      },
    },
    teacher: {
      age: 0,
      eye_id: "eye66557dd39e752a0016536401",
      l_eye_image: [
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/1f7fb4ef1ce24c7d94fcc6cbc85678e7_1.png",
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/df16b17a1744472084b6bc16f5a77d4c_2.png",
      ],
      labels: [],
      left_eye: {
        白睛脉络: {
          上: {
            color: ["深红"],
            形状: ["根部粗大"],
            诊断评分: {
              total_score: "18",
              脉络大小: "根部粗大或较粗大",
              脉络弯曲度: "呈螺旋状或曲度大",
              脉络末端斑点: "斑块或大斑点",
              脉络走向: "伸向瞳孔或离断",
              脉络颜色: "深红、绛红色",
              黑睛的改变: "凹陷穹窿代谢环变化瞳孔异常",
            },
            走行: ["走向瞳孔", "远离瞳孔"],
          },
          下: {
            color: [],
            形状: ["根部粗大", "延伸"],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: ["远离瞳孔"],
          },
          右: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          右上: {
            color: [],
            形状: ["曲张"],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          右下: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: ["走向瞳孔"],
          },
          左: {
            color: ["深红"],
            形状: ["根部粗大"],
            诊断评分: {
              total_score: "18",
              脉络大小: "根部粗大或较粗大",
              脉络弯曲度: "呈螺旋状或曲度大",
              脉络末端斑点: "斑块或大斑点",
              脉络走向: "伸向瞳孔或离断",
              脉络颜色: "深红、绛红色",
              黑睛的改变: "凹陷穹窿代谢环变化瞳孔异常",
            },
            走行: [],
          },
          左上: {
            color: ["深红"],
            形状: ["根部粗大"],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          左下: {
            color: ["深红"],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          特殊眼征: {
            特殊眼征: ["巩膜痔征", "巩膜胃征"],
          },
        },
        黑睛: {
          color: {
            color: ["正常"],
          },
          同心圆状白线: {
            全部: ["3圈"],
            同心圆状白线: "无",
            局部: ["2点"],
          },
          坑洞: {
            位置: ["3点"],
            坑洞: "无",
          },
          质地: {
            质地: ["2度"],
          },
          辐射状黑线: {
            位置: ["7点"],
            辐射状黑线: "无",
          },
          黑斑黑点: {
            位置: ["3点"],
            黑斑黑点: "无",
          },
          黑睛七环: {
            代谢环: ["偏斜"],
            环七: ["皮垢环"],
            肠道环: ["扩大"],
          },
        },
        专家建议: {
          专家建议: "",
          按诊断分: {
            中医疾病: [],
            中医证型: [],
            疾病分型: "",
            病程: "",
            西医疾病: [],
          },
          诊断评分: {
            total_score: "",
            脉络大小: "",
            脉络弯曲度: "",
            脉络末端斑点: "",
            脉络走向: "",
            脉络颜色: "",
            黑睛的改变: "",
          },
        },
      },
      name: "李四",
      patient_id: "98172e4a2336",
      project_id: "pro622ededf9e752a037f8daa62",
      r_eye_image: [
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/bfc034513d48463cbc8f070e73eb956d_左1.png",
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/7d7050c7dd4d42a3a273e73c3cc9b9a8_左3.png",
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/9d19bb608d7b4c6f9f4bbbcd88dba446_左4.png",
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/5ceee9c26ee74491937fc33df39160e6_左2.png",
      ],
      right_eye: {
        白睛脉络: {
          上: {
            color: ["深红", "鲜红"],
            形状: ["根部粗大", "曲张"],
            诊断评分: {
              total_score: "18",
              脉络大小: "根部粗大或较粗大",
              脉络弯曲度: "呈螺旋状或曲度大",
              脉络末端斑点: "斑块或大斑点",
              脉络走向: "伸向瞳孔或离断",
              脉络颜色: "深红、绛红色",
              黑睛的改变: "凹陷穹窿代谢环变化瞳孔异常",
            },
            走行: ["走向瞳孔"],
          },
          下: {
            color: ["深红", "淡红"],
            形状: ["根部粗大", "怒张"],
            诊断评分: {
              total_score: "15",
              脉络大小: "根部粗大或较粗大",
              脉络弯曲度: "蛇行状",
              脉络末端斑点: "斑块或大斑点",
              脉络走向: "伸向其他",
              脉络颜色: "鲜红色",
              黑睛的改变: "凹陷穹窿代谢环变化瞳孔异常",
            },
            走行: ["走向瞳孔"],
          },
          右: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: ["走向瞳孔"],
          },
          右上: {
            color: ["淡青"],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          右下: {
            color: ["淡青"],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          左: {
            color: [],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: ["走向瞳孔"],
          },
          左上: {
            color: ["鲜红"],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          左下: {
            color: ["深红"],
            形状: [],
            诊断评分: {
              total_score: "",
              脉络大小: "",
              脉络弯曲度: "",
              脉络末端斑点: "",
              脉络走向: "",
              脉络颜色: "",
              黑睛的改变: "",
            },
            走行: [],
          },
          特殊眼征: {
            特殊眼征: ["巩膜痔征"],
          },
        },
        黑睛: {
          color: {
            color: ["正常"],
          },
          同心圆状白线: {
            全部: ["3圈"],
            同心圆状白线: "无",
            局部: ["3点"],
          },
          坑洞: {
            位置: ["3点"],
            坑洞: "无",
          },
          质地: {
            质地: ["2度"],
          },
          辐射状黑线: {
            位置: ["3点", "全眼"],
            辐射状黑线: "无",
          },
          黑斑黑点: {
            位置: ["8点"],
            黑斑黑点: "无",
          },
          黑睛七环: {
            代谢环: ["消失"],
            环七: ["皮垢环"],
            肠道环: ["缩小"],
          },
        },
        专家建议: {
          专家建议:
            "虹膜的密度高，具有较好的厚度，提示人抗病能力强，精力体力充足，患病后康复快，容易病愈。",
          按诊断分: {
            中医疾病: [
              {
                id: "7．9",
                名称: "胃痞",
              },
            ],
            中医证型: [
              {
                id: "ABRD20",
                名称: "热毒壅盛证",
              },
              {
                id: "ABRD60",
                名称: "热毒凝结证",
              },
            ],
            疾病分型: "Ⅱ型",
            病程: "第Ⅱ阶段",
            西医疾病: [
              {
                id: "R10.102",
                名称: "胃痛",
              },
              {
                id: "K29.701",
                名称: "残胃炎",
              },
              {
                id: "K92.201",
                名称: "胃出血",
              },
              {
                id: "K31.700",
                名称: "胃息肉",
              },
              {
                id: "K31.400",
                名称: "胃憩室",
              },
              {
                id: "K31.811",
                名称: "胃下垂",
              },
              {
                id: "K29.700",
                名称: "胃炎",
              },
            ],
          },
          诊断评分: {
            total_score: "",
            脉络大小: "",
            脉络弯曲度: "",
            脉络末端斑点: "",
            脉络走向: "",
            脉络颜色: "",
            黑睛的改变: "",
          },
        },
      },
      sex: "",
      source: "测试库",
      zhenci: 1,
      zhenci_id: "66557dd29e752a00165363fe",
      专家建议: {
        专家建议:
          "虹膜的密度高，具有较好的厚度，提示人抗病能力强，精力体力充足，患病后康复快，容易病愈。",
        按诊断分: {
          中医疾病: [
            {
              id: "7．9",
              名称: "胃痞",
            },
          ],
          中医证型: [
            {
              id: "ABRD20",
              名称: "热毒壅盛证",
            },
            {
              id: "ABRD60",
              名称: "热毒凝结证",
            },
          ],
          疾病分型: "Ⅱ型",
          病程: "第Ⅱ阶段",
          西医疾病: [
            {
              id: "R10.102",
              名称: "胃痛",
            },
            {
              id: "K29.701",
              名称: "残胃炎",
            },
            {
              id: "K92.201",
              名称: "胃出血",
            },
            {
              id: "K31.700",
              名称: "胃息肉",
            },
            {
              id: "K31.400",
              名称: "胃憩室",
            },
            {
              id: "K31.811",
              名称: "胃下垂",
            },
            {
              id: "K29.700",
              名称: "胃炎",
            },
          ],
        },
        诊断评分: {
          total_score: "",
          脉络大小: "",
          脉络弯曲度: "",
          脉络末端斑点: "",
          脉络走向: "",
          脉络颜色: "",
          黑睛的改变: "",
        },
      },
      检测时间: "2024-05-28 14:46:43",
      轨迹: {
        上: [
          {
            data: {
              area: 3257.9011,
              height: 789,
              path: [
                {
                  offsetX: 666,
                  offsetY: 444,
                },
                {
                  offsetX: 665,
                  offsetY: 446,
                },
                {
                  offsetX: 661,
                  offsetY: 449,
                },
                {
                  offsetX: 656,
                  offsetY: 455,
                },
                {
                  offsetX: 650,
                  offsetY: 461,
                },
                {
                  offsetX: 644,
                  offsetY: 468,
                },
                {
                  offsetX: 638,
                  offsetY: 476,
                },
                {
                  offsetX: 627,
                  offsetY: 489,
                },
                {
                  offsetX: 621,
                  offsetY: 499,
                },
                {
                  offsetX: 615,
                  offsetY: 508,
                },
                {
                  offsetX: 612,
                  offsetY: 513,
                },
                {
                  offsetX: 606,
                  offsetY: 525,
                },
                {
                  offsetX: 603,
                  offsetY: 531,
                },
                {
                  offsetX: 601,
                  offsetY: 538,
                },
                {
                  offsetX: 599,
                  offsetY: 546,
                },
                {
                  offsetX: 597,
                  offsetY: 554,
                },
                {
                  offsetX: 596,
                  offsetY: 560,
                },
                {
                  offsetX: 595,
                  offsetY: 566,
                },
                {
                  offsetX: 594,
                  offsetY: 572,
                },
                {
                  offsetX: 593,
                  offsetY: 587,
                },
                {
                  offsetX: 592,
                  offsetY: 593,
                },
                {
                  offsetX: 592,
                  offsetY: 599,
                },
                {
                  offsetX: 590,
                  offsetY: 611,
                },
                {
                  offsetX: 588,
                  offsetY: 619,
                },
                {
                  offsetX: 587,
                  offsetY: 627,
                },
                {
                  offsetX: 586,
                  offsetY: 634,
                },
                {
                  offsetX: 586,
                  offsetY: 641,
                },
                {
                  offsetX: 585,
                  offsetY: 648,
                },
                {
                  offsetX: 584,
                  offsetY: 653,
                },
                {
                  offsetX: 583,
                  offsetY: 656,
                },
                {
                  offsetX: 583,
                  offsetY: 660,
                },
                {
                  offsetX: 582,
                  offsetY: 668,
                },
                {
                  offsetX: 581,
                  offsetY: 673,
                },
                {
                  offsetX: 581,
                  offsetY: 676,
                },
                {
                  offsetX: 581,
                  offsetY: 677,
                },
                {
                  offsetX: 581,
                  offsetY: 678,
                },
                {
                  offsetX: 581,
                  offsetY: 676,
                },
                {
                  offsetX: 586,
                  offsetY: 673,
                },
                {
                  offsetX: 591,
                  offsetY: 668,
                },
                {
                  offsetX: 603,
                  offsetY: 658,
                },
                {
                  offsetX: 607,
                  offsetY: 653,
                },
                {
                  offsetX: 611,
                  offsetY: 649,
                },
                {
                  offsetX: 613,
                  offsetY: 646,
                },
                {
                  offsetX: 617,
                  offsetY: 640,
                },
                {
                  offsetX: 619,
                  offsetY: 636,
                },
                {
                  offsetX: 622,
                  offsetY: 631,
                },
                {
                  offsetX: 624,
                  offsetY: 628,
                },
                {
                  offsetX: 626,
                  offsetY: 625,
                },
                {
                  offsetX: 627,
                  offsetY: 621,
                },
                {
                  offsetX: 631,
                  offsetY: 615,
                },
                {
                  offsetX: 634,
                  offsetY: 609,
                },
                {
                  offsetX: 637,
                  offsetY: 599,
                },
                {
                  offsetX: 640,
                  offsetY: 591,
                },
                {
                  offsetX: 644,
                  offsetY: 582,
                },
                {
                  offsetX: 646,
                  offsetY: 577,
                },
                {
                  offsetX: 651,
                  offsetY: 568,
                },
                {
                  offsetX: 657,
                  offsetY: 558,
                },
                {
                  offsetX: 661,
                  offsetY: 552,
                },
                {
                  offsetX: 664,
                  offsetY: 546,
                },
                {
                  offsetX: 667,
                  offsetY: 540,
                },
                {
                  offsetX: 670,
                  offsetY: 534,
                },
                {
                  offsetX: 672,
                  offsetY: 529,
                },
                {
                  offsetX: 675,
                  offsetY: 523,
                },
                {
                  offsetX: 676,
                  offsetY: 520,
                },
                {
                  offsetX: 677,
                  offsetY: 518,
                },
                {
                  offsetX: 677,
                  offsetY: 517,
                },
                {
                  offsetX: 678,
                  offsetY: 516,
                },
                {
                  offsetX: 678,
                  offsetY: 515,
                },
                {
                  offsetX: 681,
                  offsetY: 510,
                },
                {
                  offsetX: 681,
                  offsetY: 507,
                },
                {
                  offsetX: 682,
                  offsetY: 505,
                },
                {
                  offsetX: 683,
                  offsetY: 501,
                },
                {
                  offsetX: 684,
                  offsetY: 498,
                },
                {
                  offsetX: 685,
                  offsetY: 494,
                },
                {
                  offsetX: 685,
                  offsetY: 491,
                },
                {
                  offsetX: 686,
                  offsetY: 486,
                },
                {
                  offsetX: 687,
                  offsetY: 483,
                },
                {
                  offsetX: 687,
                  offsetY: 478,
                },
                {
                  offsetX: 687,
                  offsetY: 472,
                },
                {
                  offsetX: 687,
                  offsetY: 469,
                },
                {
                  offsetX: 687,
                  offsetY: 468,
                },
                {
                  offsetX: 687,
                  offsetY: 467,
                },
                {
                  offsetX: 687,
                  offsetY: 465,
                },
                {
                  offsetX: 687,
                  offsetY: 464,
                },
                {
                  offsetX: 687,
                  offsetY: 463,
                },
                {
                  offsetX: 687,
                  offsetY: 461,
                },
                {
                  offsetX: 686,
                  offsetY: 460,
                },
                {
                  offsetX: 686,
                  offsetY: 458,
                },
                {
                  offsetX: 685,
                  offsetY: 454,
                },
                {
                  offsetX: 684,
                  offsetY: 451,
                },
                {
                  offsetX: 683,
                  offsetY: 448,
                },
                {
                  offsetX: 683,
                  offsetY: 446,
                },
                {
                  offsetX: 682,
                  offsetY: 446,
                },
                {
                  offsetX: 682,
                  offsetY: 445,
                },
                {
                  offsetX: 682,
                  offsetY: 444,
                },
                {
                  offsetX: 681,
                  offsetY: 444,
                },
                {
                  offsetX: 680,
                  offsetY: 443,
                },
                {
                  offsetX: 679,
                  offsetY: 442,
                },
                {
                  offsetX: 678,
                  offsetY: 442,
                },
                {
                  offsetX: 677,
                  offsetY: 442,
                },
                {
                  offsetX: 677,
                  offsetY: 441,
                },
                {
                  offsetX: 676,
                  offsetY: 441,
                },
                {
                  offsetX: 675,
                  offsetY: 441,
                },
                {
                  offsetX: 674,
                  offsetY: 441,
                },
                {
                  offsetX: 673,
                  offsetY: 441,
                },
                {
                  offsetX: 672,
                  offsetY: 441,
                },
                {
                  offsetX: 671,
                  offsetY: 441,
                },
                {
                  offsetX: 670,
                  offsetY: 441,
                },
                {
                  offsetX: 669,
                  offsetY: 441,
                },
                {
                  offsetX: 668,
                  offsetY: 441,
                },
                {
                  offsetX: 667,
                  offsetY: 441,
                },
              ],
              startPoint: {
                offsetX: 667,
                offsetY: 443,
              },
              width: 1018,
            },
            id: "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/df16b17a1744472084b6bc16f5a77d4c_2.png",
          },
        ],
        下: [
          {
            data: {
              area: 4543.2647,
              height: 789,
              path: [
                {
                  offsetX: 406,
                  offsetY: 471,
                },
                {
                  offsetX: 408,
                  offsetY: 473,
                },
                {
                  offsetX: 409,
                  offsetY: 478,
                },
                {
                  offsetX: 411,
                  offsetY: 484,
                },
                {
                  offsetX: 413,
                  offsetY: 496,
                },
                {
                  offsetX: 415,
                  offsetY: 504,
                },
                {
                  offsetX: 418,
                  offsetY: 517,
                },
                {
                  offsetX: 424,
                  offsetY: 535,
                },
                {
                  offsetX: 428,
                  offsetY: 553,
                },
                {
                  offsetX: 432,
                  offsetY: 568,
                },
                {
                  offsetX: 433,
                  offsetY: 578,
                },
                {
                  offsetX: 435,
                  offsetY: 582,
                },
                {
                  offsetX: 436,
                  offsetY: 587,
                },
                {
                  offsetX: 439,
                  offsetY: 597,
                },
                {
                  offsetX: 443,
                  offsetY: 606,
                },
                {
                  offsetX: 445,
                  offsetY: 613,
                },
                {
                  offsetX: 448,
                  offsetY: 619,
                },
                {
                  offsetX: 449,
                  offsetY: 623,
                },
                {
                  offsetX: 452,
                  offsetY: 628,
                },
                {
                  offsetX: 454,
                  offsetY: 631,
                },
                {
                  offsetX: 457,
                  offsetY: 636,
                },
                {
                  offsetX: 462,
                  offsetY: 640,
                },
                {
                  offsetX: 469,
                  offsetY: 647,
                },
                {
                  offsetX: 475,
                  offsetY: 652,
                },
                {
                  offsetX: 482,
                  offsetY: 657,
                },
                {
                  offsetX: 487,
                  offsetY: 660,
                },
                {
                  offsetX: 491,
                  offsetY: 662,
                },
                {
                  offsetX: 494,
                  offsetY: 664,
                },
                {
                  offsetX: 497,
                  offsetY: 664,
                },
                {
                  offsetX: 499,
                  offsetY: 664,
                },
                {
                  offsetX: 502,
                  offsetY: 664,
                },
                {
                  offsetX: 503,
                  offsetY: 664,
                },
                {
                  offsetX: 504,
                  offsetY: 661,
                },
                {
                  offsetX: 505,
                  offsetY: 660,
                },
                {
                  offsetX: 508,
                  offsetY: 650,
                },
                {
                  offsetX: 510,
                  offsetY: 646,
                },
                {
                  offsetX: 512,
                  offsetY: 639,
                },
                {
                  offsetX: 514,
                  offsetY: 630,
                },
                {
                  offsetX: 514,
                  offsetY: 626,
                },
                {
                  offsetX: 514,
                  offsetY: 619,
                },
                {
                  offsetX: 514,
                  offsetY: 614,
                },
                {
                  offsetX: 514,
                  offsetY: 608,
                },
                {
                  offsetX: 513,
                  offsetY: 601,
                },
                {
                  offsetX: 513,
                  offsetY: 595,
                },
                {
                  offsetX: 511,
                  offsetY: 586,
                },
                {
                  offsetX: 508,
                  offsetY: 578,
                },
                {
                  offsetX: 507,
                  offsetY: 573,
                },
                {
                  offsetX: 503,
                  offsetY: 565,
                },
                {
                  offsetX: 497,
                  offsetY: 553,
                },
                {
                  offsetX: 492,
                  offsetY: 543,
                },
                {
                  offsetX: 488,
                  offsetY: 535,
                },
                {
                  offsetX: 484,
                  offsetY: 527,
                },
                {
                  offsetX: 476,
                  offsetY: 515,
                },
                {
                  offsetX: 474,
                  offsetY: 512,
                },
                {
                  offsetX: 470,
                  offsetY: 507,
                },
                {
                  offsetX: 465,
                  offsetY: 500,
                },
                {
                  offsetX: 462,
                  offsetY: 495,
                },
                {
                  offsetX: 457,
                  offsetY: 489,
                },
                {
                  offsetX: 454,
                  offsetY: 485,
                },
                {
                  offsetX: 451,
                  offsetY: 480,
                },
                {
                  offsetX: 450,
                  offsetY: 478,
                },
                {
                  offsetX: 447,
                  offsetY: 475,
                },
                {
                  offsetX: 445,
                  offsetY: 472,
                },
                {
                  offsetX: 444,
                  offsetY: 469,
                },
                {
                  offsetX: 441,
                  offsetY: 466,
                },
                {
                  offsetX: 439,
                  offsetY: 464,
                },
                {
                  offsetX: 436,
                  offsetY: 460,
                },
                {
                  offsetX: 433,
                  offsetY: 457,
                },
                {
                  offsetX: 431,
                  offsetY: 456,
                },
                {
                  offsetX: 430,
                  offsetY: 455,
                },
                {
                  offsetX: 426,
                  offsetY: 453,
                },
                {
                  offsetX: 424,
                  offsetY: 453,
                },
                {
                  offsetX: 422,
                  offsetY: 452,
                },
                {
                  offsetX: 421,
                  offsetY: 452,
                },
                {
                  offsetX: 420,
                  offsetY: 451,
                },
                {
                  offsetX: 417,
                  offsetY: 451,
                },
                {
                  offsetX: 415,
                  offsetY: 451,
                },
                {
                  offsetX: 414,
                  offsetY: 451,
                },
                {
                  offsetX: 413,
                  offsetY: 451,
                },
                {
                  offsetX: 412,
                  offsetY: 452,
                },
                {
                  offsetX: 412,
                  offsetY: 453,
                },
                {
                  offsetX: 412,
                  offsetY: 454,
                },
                {
                  offsetX: 412,
                  offsetY: 456,
                },
                {
                  offsetX: 412,
                  offsetY: 459,
                },
                {
                  offsetX: 412,
                  offsetY: 460,
                },
                {
                  offsetX: 412,
                  offsetY: 461,
                },
                {
                  offsetX: 412,
                  offsetY: 462,
                },
                {
                  offsetX: 411,
                  offsetY: 463,
                },
                {
                  offsetX: 411,
                  offsetY: 466,
                },
                {
                  offsetX: 411,
                  offsetY: 468,
                },
                {
                  offsetX: 410,
                  offsetY: 471,
                },
                {
                  offsetX: 410,
                  offsetY: 474,
                },
                {
                  offsetX: 410,
                  offsetY: 475,
                },
                {
                  offsetX: 410,
                  offsetY: 478,
                },
                {
                  offsetX: 410,
                  offsetY: 479,
                },
                {
                  offsetX: 410,
                  offsetY: 481,
                },
                {
                  offsetX: 410,
                  offsetY: 482,
                },
                {
                  offsetX: 410,
                  offsetY: 483,
                },
                {
                  offsetX: 411,
                  offsetY: 483,
                },
                {
                  offsetX: 411,
                  offsetY: 484,
                },
              ],
              startPoint: {
                offsetX: 404,
                offsetY: 469,
              },
              width: 1018,
            },
            id: "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/df16b17a1744472084b6bc16f5a77d4c_2.png",
          },
        ],
        左上: [
          {
            data: {
              area: 166.38905,
              height: 465,
              path: [
                {
                  offsetX: 153,
                  offsetY: 312,
                },
                {
                  offsetX: 154,
                  offsetY: 310,
                },
                {
                  offsetX: 156,
                  offsetY: 310,
                },
                {
                  offsetX: 160,
                  offsetY: 307,
                },
                {
                  offsetX: 163,
                  offsetY: 305,
                },
                {
                  offsetX: 167,
                  offsetY: 304,
                },
                {
                  offsetX: 173,
                  offsetY: 301,
                },
                {
                  offsetX: 180,
                  offsetY: 298,
                },
                {
                  offsetX: 188,
                  offsetY: 295,
                },
                {
                  offsetX: 196,
                  offsetY: 293,
                },
                {
                  offsetX: 207,
                  offsetY: 289,
                },
                {
                  offsetX: 216,
                  offsetY: 287,
                },
                {
                  offsetX: 230,
                  offsetY: 285,
                },
                {
                  offsetX: 243,
                  offsetY: 284,
                },
                {
                  offsetX: 255,
                  offsetY: 284,
                },
                {
                  offsetX: 267,
                  offsetY: 284,
                },
                {
                  offsetX: 278,
                  offsetY: 284,
                },
                {
                  offsetX: 286,
                  offsetY: 284,
                },
                {
                  offsetX: 294,
                  offsetY: 284,
                },
                {
                  offsetX: 298,
                  offsetY: 287,
                },
                {
                  offsetX: 302,
                  offsetY: 288,
                },
                {
                  offsetX: 304,
                  offsetY: 291,
                },
                {
                  offsetX: 305,
                  offsetY: 294,
                },
                {
                  offsetX: 306,
                  offsetY: 297,
                },
                {
                  offsetX: 306,
                  offsetY: 301,
                },
                {
                  offsetX: 306,
                  offsetY: 306,
                },
                {
                  offsetX: 306,
                  offsetY: 312,
                },
                {
                  offsetX: 306,
                  offsetY: 319,
                },
                {
                  offsetX: 305,
                  offsetY: 327,
                },
                {
                  offsetX: 303,
                  offsetY: 336,
                },
                {
                  offsetX: 299,
                  offsetY: 346,
                },
                {
                  offsetX: 296,
                  offsetY: 355,
                },
                {
                  offsetX: 292,
                  offsetY: 363,
                },
                {
                  offsetX: 287,
                  offsetY: 372,
                },
                {
                  offsetX: 282,
                  offsetY: 379,
                },
                {
                  offsetX: 277,
                  offsetY: 385,
                },
                {
                  offsetX: 271,
                  offsetY: 391,
                },
                {
                  offsetX: 265,
                  offsetY: 396,
                },
                {
                  offsetX: 257,
                  offsetY: 402,
                },
                {
                  offsetX: 249,
                  offsetY: 406,
                },
                {
                  offsetX: 240,
                  offsetY: 410,
                },
                {
                  offsetX: 232,
                  offsetY: 412,
                },
                {
                  offsetX: 221,
                  offsetY: 414,
                },
                {
                  offsetX: 210,
                  offsetY: 415,
                },
                {
                  offsetX: 198,
                  offsetY: 417,
                },
                {
                  offsetX: 186,
                  offsetY: 417,
                },
                {
                  offsetX: 175,
                  offsetY: 417,
                },
                {
                  offsetX: 163,
                  offsetY: 417,
                },
                {
                  offsetX: 153,
                  offsetY: 415,
                },
                {
                  offsetX: 145,
                  offsetY: 413,
                },
                {
                  offsetX: 139,
                  offsetY: 410,
                },
                {
                  offsetX: 134,
                  offsetY: 408,
                },
                {
                  offsetX: 132,
                  offsetY: 404,
                },
                {
                  offsetX: 131,
                  offsetY: 400,
                },
                {
                  offsetX: 130,
                  offsetY: 394,
                },
                {
                  offsetX: 130,
                  offsetY: 387,
                },
                {
                  offsetX: 132,
                  offsetY: 380,
                },
                {
                  offsetX: 135,
                  offsetY: 371,
                },
                {
                  offsetX: 142,
                  offsetY: 358,
                },
                {
                  offsetX: 149,
                  offsetY: 345,
                },
                {
                  offsetX: 158,
                  offsetY: 331,
                },
                {
                  offsetX: 168,
                  offsetY: 317,
                },
                {
                  offsetX: 180,
                  offsetY: 303,
                },
                {
                  offsetX: 192,
                  offsetY: 292,
                },
                {
                  offsetX: 207,
                  offsetY: 278,
                },
                {
                  offsetX: 226,
                  offsetY: 267,
                },
                {
                  offsetX: 242,
                  offsetY: 257,
                },
                {
                  offsetX: 263,
                  offsetY: 247,
                },
                {
                  offsetX: 283,
                  offsetY: 238,
                },
                {
                  offsetX: 300,
                  offsetY: 232,
                },
                {
                  offsetX: 314,
                  offsetY: 227,
                },
                {
                  offsetX: 326,
                  offsetY: 224,
                },
                {
                  offsetX: 333,
                  offsetY: 222,
                },
                {
                  offsetX: 339,
                  offsetY: 221,
                },
                {
                  offsetX: 341,
                  offsetY: 221,
                },
                {
                  offsetX: 342,
                  offsetY: 221,
                },
              ],
              startPoint: {
                offsetX: 152,
                offsetY: 312,
              },
              width: 600,
            },
            id: "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/1f7fb4ef1ce24c7d94fcc6cbc85678e7_1.png",
          },
        ],
        黑睛: [
          {
            data: {
              area: 1160.0079,
              height: 789,
              path: [
                {
                  offsetX: 599,
                  offsetY: 364,
                },
                {
                  offsetX: 587,
                  offsetY: 369,
                },
                {
                  offsetX: 582,
                  offsetY: 373,
                },
                {
                  offsetX: 579,
                  offsetY: 376,
                },
                {
                  offsetX: 576,
                  offsetY: 381,
                },
                {
                  offsetX: 573,
                  offsetY: 385,
                },
                {
                  offsetX: 569,
                  offsetY: 391,
                },
                {
                  offsetX: 565,
                  offsetY: 400,
                },
                {
                  offsetX: 562,
                  offsetY: 407,
                },
                {
                  offsetX: 561,
                  offsetY: 413,
                },
                {
                  offsetX: 559,
                  offsetY: 422,
                },
                {
                  offsetX: 559,
                  offsetY: 430,
                },
                {
                  offsetX: 559,
                  offsetY: 436,
                },
                {
                  offsetX: 559,
                  offsetY: 446,
                },
                {
                  offsetX: 560,
                  offsetY: 451,
                },
                {
                  offsetX: 561,
                  offsetY: 458,
                },
                {
                  offsetX: 564,
                  offsetY: 464,
                },
                {
                  offsetX: 570,
                  offsetY: 477,
                },
                {
                  offsetX: 578,
                  offsetY: 491,
                },
                {
                  offsetX: 589,
                  offsetY: 504,
                },
                {
                  offsetX: 610,
                  offsetY: 522,
                },
                {
                  offsetX: 628,
                  offsetY: 532,
                },
                {
                  offsetX: 644,
                  offsetY: 537,
                },
                {
                  offsetX: 657,
                  offsetY: 539,
                },
                {
                  offsetX: 676,
                  offsetY: 538,
                },
                {
                  offsetX: 698,
                  offsetY: 530,
                },
                {
                  offsetX: 717,
                  offsetY: 521,
                },
                {
                  offsetX: 737,
                  offsetY: 512,
                },
                {
                  offsetX: 758,
                  offsetY: 501,
                },
                {
                  offsetX: 772,
                  offsetY: 491,
                },
                {
                  offsetX: 783,
                  offsetY: 480,
                },
                {
                  offsetX: 793,
                  offsetY: 468,
                },
                {
                  offsetX: 798,
                  offsetY: 456,
                },
                {
                  offsetX: 803,
                  offsetY: 435,
                },
                {
                  offsetX: 805,
                  offsetY: 421,
                },
                {
                  offsetX: 807,
                  offsetY: 410,
                },
                {
                  offsetX: 808,
                  offsetY: 401,
                },
                {
                  offsetX: 808,
                  offsetY: 396,
                },
                {
                  offsetX: 808,
                  offsetY: 390,
                },
                {
                  offsetX: 808,
                  offsetY: 386,
                },
                {
                  offsetX: 808,
                  offsetY: 381,
                },
                {
                  offsetX: 805,
                  offsetY: 376,
                },
                {
                  offsetX: 803,
                  offsetY: 373,
                },
                {
                  offsetX: 800,
                  offsetY: 368,
                },
                {
                  offsetX: 794,
                  offsetY: 361,
                },
                {
                  offsetX: 791,
                  offsetY: 358,
                },
                {
                  offsetX: 786,
                  offsetY: 355,
                },
                {
                  offsetX: 779,
                  offsetY: 351,
                },
                {
                  offsetX: 774,
                  offsetY: 347,
                },
                {
                  offsetX: 770,
                  offsetY: 346,
                },
                {
                  offsetX: 765,
                  offsetY: 344,
                },
                {
                  offsetX: 760,
                  offsetY: 342,
                },
                {
                  offsetX: 756,
                  offsetY: 342,
                },
                {
                  offsetX: 751,
                  offsetY: 341,
                },
                {
                  offsetX: 747,
                  offsetY: 341,
                },
                {
                  offsetX: 743,
                  offsetY: 340,
                },
                {
                  offsetX: 737,
                  offsetY: 340,
                },
                {
                  offsetX: 733,
                  offsetY: 340,
                },
                {
                  offsetX: 728,
                  offsetY: 340,
                },
                {
                  offsetX: 722,
                  offsetY: 340,
                },
                {
                  offsetX: 718,
                  offsetY: 341,
                },
                {
                  offsetX: 714,
                  offsetY: 341,
                },
                {
                  offsetX: 709,
                  offsetY: 342,
                },
                {
                  offsetX: 704,
                  offsetY: 343,
                },
                {
                  offsetX: 700,
                  offsetY: 344,
                },
                {
                  offsetX: 694,
                  offsetY: 344,
                },
                {
                  offsetX: 688,
                  offsetY: 346,
                },
                {
                  offsetX: 682,
                  offsetY: 347,
                },
                {
                  offsetX: 675,
                  offsetY: 348,
                },
                {
                  offsetX: 672,
                  offsetY: 349,
                },
                {
                  offsetX: 669,
                  offsetY: 349,
                },
                {
                  offsetX: 666,
                  offsetY: 350,
                },
                {
                  offsetX: 664,
                  offsetY: 350,
                },
                {
                  offsetX: 660,
                  offsetY: 350,
                },
                {
                  offsetX: 658,
                  offsetY: 351,
                },
                {
                  offsetX: 655,
                  offsetY: 352,
                },
                {
                  offsetX: 653,
                  offsetY: 352,
                },
                {
                  offsetX: 652,
                  offsetY: 352,
                },
                {
                  offsetX: 651,
                  offsetY: 353,
                },
                {
                  offsetX: 649,
                  offsetY: 353,
                },
                {
                  offsetX: 647,
                  offsetY: 353,
                },
                {
                  offsetX: 645,
                  offsetY: 354,
                },
                {
                  offsetX: 642,
                  offsetY: 355,
                },
                {
                  offsetX: 640,
                  offsetY: 355,
                },
                {
                  offsetX: 637,
                  offsetY: 355,
                },
                {
                  offsetX: 635,
                  offsetY: 356,
                },
                {
                  offsetX: 632,
                  offsetY: 356,
                },
                {
                  offsetX: 628,
                  offsetY: 356,
                },
                {
                  offsetX: 625,
                  offsetY: 356,
                },
                {
                  offsetX: 622,
                  offsetY: 357,
                },
                {
                  offsetX: 620,
                  offsetY: 358,
                },
                {
                  offsetX: 618,
                  offsetY: 358,
                },
                {
                  offsetX: 614,
                  offsetY: 359,
                },
                {
                  offsetX: 612,
                  offsetY: 359,
                },
                {
                  offsetX: 611,
                  offsetY: 360,
                },
                {
                  offsetX: 610,
                  offsetY: 360,
                },
                {
                  offsetX: 609,
                  offsetY: 360,
                },
                {
                  offsetX: 608,
                  offsetY: 360,
                },
                {
                  offsetX: 607,
                  offsetY: 360,
                },
                {
                  offsetX: 607,
                  offsetY: 361,
                },
                {
                  offsetX: 606,
                  offsetY: 361,
                },
                {
                  offsetX: 605,
                  offsetY: 361,
                },
                {
                  offsetX: 604,
                  offsetY: 361,
                },
              ],
              startPoint: {
                offsetX: 604,
                offsetY: 363,
              },
              width: 1018,
            },
            id: "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/1f7fb4ef1ce24c7d94fcc6cbc85678e7_1.png",
          },
          {
            data: {
              area: 15742.558599999998,
              height: 789,
              path: [
                {
                  offsetX: 557,
                  offsetY: 278,
                },
                {
                  offsetX: 556,
                  offsetY: 278,
                },
                {
                  offsetX: 552,
                  offsetY: 278,
                },
                {
                  offsetX: 546,
                  offsetY: 278,
                },
                {
                  offsetX: 534,
                  offsetY: 280,
                },
                {
                  offsetX: 524,
                  offsetY: 283,
                },
                {
                  offsetX: 510,
                  offsetY: 289,
                },
                {
                  offsetX: 495,
                  offsetY: 299,
                },
                {
                  offsetX: 465,
                  offsetY: 324,
                },
                {
                  offsetX: 441,
                  offsetY: 347,
                },
                {
                  offsetX: 422,
                  offsetY: 371,
                },
                {
                  offsetX: 402,
                  offsetY: 399,
                },
                {
                  offsetX: 392,
                  offsetY: 418,
                },
                {
                  offsetX: 388,
                  offsetY: 431,
                },
                {
                  offsetX: 388,
                  offsetY: 440,
                },
                {
                  offsetX: 388,
                  offsetY: 444,
                },
                {
                  offsetX: 390,
                  offsetY: 449,
                },
                {
                  offsetX: 394,
                  offsetY: 458,
                },
                {
                  offsetX: 397,
                  offsetY: 465,
                },
                {
                  offsetX: 403,
                  offsetY: 477,
                },
                {
                  offsetX: 415,
                  offsetY: 491,
                },
                {
                  offsetX: 424,
                  offsetY: 500,
                },
                {
                  offsetX: 433,
                  offsetY: 506,
                },
                {
                  offsetX: 445,
                  offsetY: 512,
                },
                {
                  offsetX: 458,
                  offsetY: 516,
                },
                {
                  offsetX: 484,
                  offsetY: 518,
                },
                {
                  offsetX: 512,
                  offsetY: 520,
                },
                {
                  offsetX: 541,
                  offsetY: 520,
                },
                {
                  offsetX: 570,
                  offsetY: 520,
                },
                {
                  offsetX: 596,
                  offsetY: 518,
                },
                {
                  offsetX: 613,
                  offsetY: 514,
                },
                {
                  offsetX: 636,
                  offsetY: 504,
                },
                {
                  offsetX: 658,
                  offsetY: 482,
                },
                {
                  offsetX: 667,
                  offsetY: 467,
                },
                {
                  offsetX: 672,
                  offsetY: 454,
                },
                {
                  offsetX: 676,
                  offsetY: 439,
                },
                {
                  offsetX: 678,
                  offsetY: 426,
                },
                {
                  offsetX: 678,
                  offsetY: 413,
                },
                {
                  offsetX: 678,
                  offsetY: 397,
                },
                {
                  offsetX: 678,
                  offsetY: 383,
                },
                {
                  offsetX: 677,
                  offsetY: 373,
                },
                {
                  offsetX: 674,
                  offsetY: 367,
                },
                {
                  offsetX: 670,
                  offsetY: 360,
                },
                {
                  offsetX: 667,
                  offsetY: 357,
                },
                {
                  offsetX: 659,
                  offsetY: 348,
                },
                {
                  offsetX: 653,
                  offsetY: 344,
                },
                {
                  offsetX: 649,
                  offsetY: 340,
                },
                {
                  offsetX: 643,
                  offsetY: 334,
                },
                {
                  offsetX: 636,
                  offsetY: 329,
                },
                {
                  offsetX: 626,
                  offsetY: 322,
                },
                {
                  offsetX: 617,
                  offsetY: 315,
                },
                {
                  offsetX: 609,
                  offsetY: 310,
                },
                {
                  offsetX: 603,
                  offsetY: 306,
                },
                {
                  offsetX: 597,
                  offsetY: 302,
                },
                {
                  offsetX: 591,
                  offsetY: 299,
                },
                {
                  offsetX: 587,
                  offsetY: 296,
                },
                {
                  offsetX: 585,
                  offsetY: 295,
                },
                {
                  offsetX: 584,
                  offsetY: 295,
                },
                {
                  offsetX: 583,
                  offsetY: 294,
                },
                {
                  offsetX: 582,
                  offsetY: 293,
                },
                {
                  offsetX: 581,
                  offsetY: 293,
                },
                {
                  offsetX: 580,
                  offsetY: 293,
                },
                {
                  offsetX: 579,
                  offsetY: 293,
                },
                {
                  offsetX: 579,
                  offsetY: 292,
                },
                {
                  offsetX: 578,
                  offsetY: 292,
                },
                {
                  offsetX: 577,
                  offsetY: 292,
                },
                {
                  offsetX: 576,
                  offsetY: 291,
                },
                {
                  offsetX: 575,
                  offsetY: 291,
                },
                {
                  offsetX: 575,
                  offsetY: 290,
                },
                {
                  offsetX: 573,
                  offsetY: 290,
                },
                {
                  offsetX: 572,
                  offsetY: 289,
                },
                {
                  offsetX: 571,
                  offsetY: 288,
                },
                {
                  offsetX: 570,
                  offsetY: 288,
                },
                {
                  offsetX: 569,
                  offsetY: 287,
                },
                {
                  offsetX: 568,
                  offsetY: 287,
                },
                {
                  offsetX: 568,
                  offsetY: 286,
                },
                {
                  offsetX: 567,
                  offsetY: 285,
                },
                {
                  offsetX: 566,
                  offsetY: 285,
                },
                {
                  offsetX: 565,
                  offsetY: 285,
                },
                {
                  offsetX: 565,
                  offsetY: 284,
                },
                {
                  offsetX: 564,
                  offsetY: 284,
                },
                {
                  offsetX: 564,
                  offsetY: 283,
                },
                {
                  offsetX: 563,
                  offsetY: 283,
                },
                {
                  offsetX: 562,
                  offsetY: 283,
                },
                {
                  offsetX: 561,
                  offsetY: 283,
                },
                {
                  offsetX: 561,
                  offsetY: 282,
                },
                {
                  offsetX: 560,
                  offsetY: 281,
                },
                {
                  offsetX: 559,
                  offsetY: 281,
                },
                {
                  offsetX: 559,
                  offsetY: 280,
                },
                {
                  offsetX: 558,
                  offsetY: 280,
                },
                {
                  offsetX: 557,
                  offsetY: 280,
                },
                {
                  offsetX: 556,
                  offsetY: 280,
                },
                {
                  offsetX: 556,
                  offsetY: 279,
                },
              ],
              startPoint: {
                offsetX: 558,
                offsetY: 278,
              },
              width: 1018,
            },
            id: "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/7d7050c7dd4d42a3a273e73c3cc9b9a8_左3.png",
          },
        ],
      },
    },
  };
  private changeTab(item: any) {
    this.type = item;
  }
  private getDetail() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        eye_id: this.id,
      },
    };
    GetEyeReport(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data;
      })
      .catch(() => {
        loading.close();
      });
  }
  private mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
  }
}
